import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { computed } from 'vue';

import Col3Layout from '@/components/layouts/Col3Layout.vue';
import usePoolQuery from '@/composables/queries/usePoolQuery';
import useVeBalLockInfoQuery from '@/composables/queries/useVeBalLockInfoQuery';
import useTokens from '@/composables/useTokens';
import useVeBal from '@/composables/useVeBAL';
import { Pool } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

import MyVeBAL from '../LockForm/components/MyVeBAL.vue';
import VeBalUnlockForm from './components/VeBalUnlockForm/VeBalUnlockForm.vue';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { getToken } = useTokens();
const { isWalletReady } = useWeb3();
const { lockablePoolId } = useVeBal();

/**
 * QUERIES
 */
const lockablePoolQuery = usePoolQuery(lockablePoolId.value as string);
const veBalLockInfoQuery = useVeBalLockInfoQuery();

/**
 * COMPUTED
 */
const lockablePoolLoading = computed(
  () => lockablePoolQuery.isLoading.value || lockablePoolQuery.isIdle.value
);

const veBalQueryLoading = computed(
  () => veBalLockInfoQuery.isLoading.value || veBalLockInfoQuery.isIdle.value
);

const lockablePool = computed<Pool | undefined>(
  () => lockablePoolQuery.data.value
);

const lockablePoolTokenInfo = computed(() =>
  lockablePool.value != null ? getToken(lockablePool.value.address) : null
);

const veBalLockInfo = computed(() => veBalLockInfoQuery.data.value);

const isLoading = computed(() =>
  isWalletReady.value
    ? lockablePoolLoading.value || veBalQueryLoading.value
    : lockablePoolLoading.value
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createBlock(Col3Layout, { offsetGutters: "" }, {
    gutterRight: _withCtx(() => [
      (_unref(isLoading))
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-64"
          }))
        : (_openBlock(), _createBlock(MyVeBAL, {
            key: 1,
            veBalLockInfo: _unref(veBalLockInfo)
          }, null, 8, ["veBalLockInfo"]))
    ]),
    default: _withCtx(() => [
      (_unref(isLoading))
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-96"
          }))
        : (_openBlock(), _createBlock(VeBalUnlockForm, {
            key: 
        _unref(veBalLockInfo)?.hasExistingLock
          ? 'veBalUnlockForm-hasLock'
          : 'veBalUnlockForm-noLock'
      ,
            lockablePool: _unref(lockablePool),
            lockablePoolTokenInfo: _unref(lockablePoolTokenInfo),
            veBalLockInfo: _unref(veBalLockInfo)
          }, null, 8, ["lockablePool", "lockablePoolTokenInfo", "veBalLockInfo"]))
    ]),
    _: 1
  }))
}
}

})